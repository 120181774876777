import React, { useEffect, useState } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "./scale-calculator.css"

const AVAILABLE_FRACTIONS = [0, 1 / 8, 2 / 8, 3 / 8, 4 / 8, 5 / 8, 6 / 8, 7 / 8]
const FRACTION_CHARACTERS = {
  ["0"]: "",
  [`${1 / 8}`]: "⅛",
  [`${2 / 8}`]: "¼",
  [`${3 / 8}`]: "⅜",
  [`${4 / 8}`]: "½",
  [`${5 / 8}`]: "⅝",
  [`${6 / 8}`]: "¾",
  [`${7 / 8}`]: "⅞",
}
const SCALE_SIZES_TO_NAMES = {
  // ["1"]: '1" scale',
  // ["1.5"]: '1.5" scale',
  // ["1.6"]: '1.6" scale',
  // ["2.5"]: '2.5" scale',
  ["12"]: "life-size",
}

const round = (number, places) => +number.toFixed(places)

const getFractionString = inches => {
  const needle = inches % 1
  const nearest = AVAILABLE_FRACTIONS.sort((a, b) => {
    return Math.abs(needle - a) - Math.abs(needle - b)
  })[0]
  return FRACTION_CHARACTERS[`${nearest}`]
}

const constructString = inches => {
  if (inches === 0) {
    return "Please enter a value"
  }

  let resultString = ""

  resultString += `${round(inches, 3)} inches or `

  // If the decimal value is approximate, then add a tilde to the output
  if (!AVAILABLE_FRACTIONS.includes(inches % 1)) {
    resultString += "about "
  }

  // If it's more than a foot, let's start with that
  if (inches > 12) {
    resultString += `${Math.floor(inches / 12)}' `
  }

  if (Math.floor(inches % 12) >= 1) {
    resultString += `${Math.floor(inches % 12)}`
  }

  // If there's a decimal, show it
  if (inches % 1 > 0) {
    resultString += getFractionString(inches)
  }

  if (
    (inches % 1 > 0 && getFractionString(inches).length) ||
    Math.floor(inches % 12) >= 1
  ) {
    resultString += '"'
  }

  return resultString
}

const ScaleCalculatorPage = () => {
  const [scaleRaw, setScaleRaw] = useState(12)
  const [targetScaleRaw, setTargetScaleRaw] = useState(1.6)
  const [feetRaw, setFeetRaw] = useState(20)
  const [inchesRaw, setInchesRaw] = useState(0)
  const [fractionRaw, setFractionRaw] = useState(0)
  const [result, setResult] = useState("")

  useEffect(() => {
    const scale = parseFloat(scaleRaw)
    const targetScale = parseFloat(targetScaleRaw)
    const feet = parseFloat(feetRaw)
    const inches = parseFloat(inchesRaw)
    const fraction = parseFloat(fractionRaw)

    let toScale = 0

    if (isNaN(feet)) {
      setResult("Please enter a valid number for feet")
    } else if (isNaN(inches)) {
      setResult("Please enter a valid number for inches")
    } else if (isNaN(scale)) {
      setResult("Please enter a valid number for the measured scale")
    } else if (isNaN(targetScale)) {
      setResult("Please enter a valid number for the target scale")
    } else {
      const original = feet * 12 + inches + fraction
      toScale = (original * (targetScale / 12)) / (scale / 12)

      setResult(constructString(toScale))
    }
  }, [scaleRaw, feetRaw, inchesRaw, fractionRaw, targetScaleRaw])

  return (
    <Layout>
      <SEO title="Scale Calculator" />
      <div className="container scale-calculator">
        <h2>Scale Calculator</h2>
        <p>
          Use the calculator below to calculate scale dimensions. Simply enter
          the feet and inches of the known size in the &quot;Known size&quot;
          section on the left and see the scaled dimensions in the &quot;Target
          scale&quot; section on the right.
        </p>
        <p>
          You can edit either the &quot;known&quot; or the &quot;target&quot;
          scale (in inches per foot) by entering another value in the
          &quot;Scale&quot; textbox.
        </p>
        <div className="scale-card-container">
          <section
            className="original-size"
            style={{
              backgroundColor: "white",
              flex: 1,
              padding: 15,
              borderRadius: 10,
            }}
          >
            <div className="header-container">
              <h3>Known size</h3>
            </div>
            <div className="input-container">
              <label>Scale</label>
              <div style={{ display: "inline-block" }}>
                <input
                  type="number"
                  className="scale-input"
                  name="scale"
                  value={scaleRaw}
                  onChange={e => setScaleRaw(e.target.value)}
                  min="0.4"
                  max="12"
                  step="0.1"
                />
                &quot; per foot scale
                {Object.keys(SCALE_SIZES_TO_NAMES).includes(`${scaleRaw}`)
                  ? `(${SCALE_SIZES_TO_NAMES[`${scaleRaw}`]})`
                  : ""}
              </div>
            </div>
            <div className="input-container">
              <label>Size</label>
              <div style={{ display: "inline-block" }}>
                <input
                  name="feet"
                  type="number"
                  value={feetRaw}
                  onChange={e => setFeetRaw(e.target.value)}
                  min="0"
                  max="10000"
                />
                &apos;
                <input
                  name="inches"
                  type="number"
                  value={inchesRaw}
                  onChange={e => setInchesRaw(e.target.value)}
                  min="0"
                  max="11"
                />
                <select
                  name="fraction"
                  value={fractionRaw}
                  onChange={e => setFractionRaw(Number(e.target.value))}
                >
                  <option value="0">0</option>
                  <option value="0.25">¼</option>
                  <option value="0.5">½</option>
                  <option value="0.75">¾</option>
                </select>
                &quot;
              </div>
            </div>
          </section>
          <section
            className="scale-size"
            style={{
              backgroundColor: "white",
              flex: 1,
              borderRadius: 10,
              padding: 15,
            }}
          >
            <div className="header-container">
              <h3>Target size</h3>
            </div>
            <div className="input-container">
              <label>Scale</label>
              <div style={{ display: "inline-block" }}>
                <input
                  type="number"
                  name="target_scale"
                  className="scale-input"
                  value={targetScaleRaw}
                  onChange={e => setTargetScaleRaw(e.target.value)}
                  min="0.4"
                  max="12"
                  step="0.1"
                />
                &quot; per foot scale
                {Object.keys(SCALE_SIZES_TO_NAMES).includes(`${targetScaleRaw}`)
                  ? `(${SCALE_SIZES_TO_NAMES[`${targetScaleRaw}`]})`
                  : ""}
              </div>
            </div>
            <div className="input-container">
              <label>Size</label>
              <output>{result}</output>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default ScaleCalculatorPage
